@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body {
  font-family: "Inter";
  background-color: unset !important;
}

html {
  padding: 47px;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 960px) {
    padding: 0px;
    height: 100vh;
    background: #FFFFFF;
  }
}

option {
  color: black;
}

.apexcharts-menu-item {
  color: #E56B20;
}

.video-container {
  position: relative;
  width: fit-content;
}

.video-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
}

.video-container:hover .video-controls {
  display: block;
}